import './App.css';

function App() {

  const cookies = `; ${document.cookie}`.split(`; v-auth=`);
  const vAuthCookie = (cookies.length === 2) ? true : false;

  return (
    <div>
      <div className="logo-container">
        <img src="images/home_white.png" className="logo" alt="" />
      </div>
      { vAuthCookie ? (
        <div className="landing-menu">
          <span>
            <a href="https://icloud.com">icloud</a><br />
            <a href="http://mail.voydanoff.com/">gmail</a><br />
            <a href="https://voydanoff.signin.aws.amazon.com/console">aws</a><br />
            <a href="https://mint.voydanoff.com/">mint</a><br />
          </span>
        </div>
      ) : ''}
    </div>
  );
}

export default App;
